import React from "react"
import { StaticImage } from "gatsby-plugin-image"

const HomeSolutions = () => {
    return (
        <section data-scrollax-parent="true" id="KPIs">
            <div className="section-subtitle" data-scrollax="properties: { translateY: '-250px' }" ><span>//</span>highlights </div>
            <div className="container">
                <div className="section-title fl-wrap">
                    <h3>KPIs</h3>
                    <h2>DIGITAL OUTREACH <span> HIGHLIGHTS</span></h2>
          
                </div>
                <div className="process-wrap fl-wrap">
                    <ul>
                        <li>
                            <div className="time-line-icon">
                                <i className="fab pragicts-cohesive-icon"></i>
                            </div>
                            <h4>Holistic and cohesive solutions and service delivery</h4>
                            <div className="process-details">
                                {/* <h6>Duis autem vel eum iriure dolor</h6> */}
                                <p>We can provide the complete solution or service offering to meet our client's needs</p>
                                {/* <a href="#">More Details</a> */}
                            </div>
                            <span className="process-numder">01.</span>
                        </li>
                        <li>
                            <div className="time-line-icon">
                                <i className="fal pragicts-expertise-icon"></i>
                            </div>
                            <h4>Technical Expertise Across Key Domains</h4>
                            <div className="process-details">
                                {/* <h6>In ut odio libero, at vulputate urna. </h6> */}
                                <p>Technical Expertise Across relevant domains to articulate, design, and engineer comprehensive solutions that are cohesive and complete.</p>
                                {/* <a href="#">More Details</a> */}
                            </div>
                            <span className="process-numder">02.</span>
                        </li>
                        <li>
                            <div className="time-line-icon">
                                <i className="fal pragicts-global-icon"></i>
                            </div>
                            <h4>Global Tier 1 Technology Partnerships</h4>
                            <div className="process-details">
                                {/* <h6>Nulla posuere sapien vitae lectus suscipit</h6> */}
                                <p>We have comprehensive and cohesive partnerships with global tier 1 companies in domains relating to sensors, IoT, wireless connectivity, etc. for solutions and services leadership.</p>
                                {/* <a href="#">More Details</a> */}
                            </div>
                            <span className="process-numder">03.</span>
                        </li>
                        <li>
                            <div className="time-line-icon">
                                <i className="fal pragicts-track-icon"></i>
                            </div>
                            <h4>Engineering And Delivery Track Record</h4>
                            <div className="process-details">
                                {/* <h6>Nulla posuere sapien vitae lectus suscipit</h6> */}
                                <p>We have an impeccable track record in engineering and delivery as evidenced by our claims and statements.</p>
                                {/* <a href="#">More Details</a> */}
                            </div>
                            <span className="process-numder">04.</span>
                        </li>
                        <li>
                            <div className="time-line-icon">
                                <i className="fal pragicts-highlights-icon"></i>
                            </div>
                            <h4>Capacity For Tailored Solutions</h4>
                            <div className="process-details">
                                {/* <h6>Nulla posuere sapien vitae lectus suscipit</h6> */}
                                <p>As every fingerprint is unique, so are the solutions and services to each client, project, and engagement. Our services and solutions are fully tailored to meet our customers' exact requirements.</p>
                                {/* <a href="#">More Details</a> */}
                            </div>
                            <span className="process-numder">04.</span>
                        </li>
                    </ul>
                </div>
            </div>
           
            <div className="sec-lines"></div>
        </section>
    )
}

export default HomeSolutions
