import React from 'react'


import prometheus from '../images/technologies/tech-prometheus-a7dcee35e78dc1d81752ef09b419760c.png'
import grafana from '../images/technologies/tech-grafana-8a1edfed75528afa4fd2f82c7c73c299.png'
import linux from '../images/technologies/linux.png'
import python from '../images/technologies/tech/python.png'
import chatgpt from '../images/technologies/tech/chatgpt.png'
import azure from '../images/technologies/tech/azure.png'
import aws from '../images/technologies/tech/aws.png'
import gcp from '../images/technologies/tech/gcp.png'
import schneider from '../images/technologies/schneider.png'
import ansible from '../images/technologies/ansible.png'

import alertmanager from '../images/technologies/alertmanager.png'
import dji from '../images/technologies/dji.png'
import jiyi from '../images/technologies/jiyi.png'
import lorawan from '../images/technologies/lorawan.png'
import milesight from '../images/technologies/milesight.png'
import salt from '../images/technologies/salt.png'
import vutlan from '../images/technologies/vutlan.png'
import xag from '../images/technologies/xag.png'
import zabbix from '../images/technologies/zabbix.png'

const clients = [


    {
        name: 'Schneider',
        logo: schneider,
        url: 'https://www.se.com/'
    },
  
    {
        name: 'Python',
        logo: python,
        url: 'https://www.python.org/'
    },
    
    {
        name: 'Aws',
        logo: aws,
        url: 'https://aws.amazon.com/'
    },
    {
        name: 'Azure',
        logo: azure,
        url: 'https://azure.microsoft.com/en-us/'
    },
    {
        name: 'GCP',
        logo: gcp,
        url: 'https://cloud.google.com/'
    },
    {
        name: 'Chatgpt',
        logo: chatgpt,
        url: 'https://chat.openai.com/'
    },
    {
        name: 'prometheus',
        logo: prometheus,
        url: 'https://prometheus.io/'
    },
    {
        name: 'grafana',
        logo: grafana,
        url: 'https://grafana.com/'
    },
   
    {
        name: 'linux',
        logo: linux,
        url: 'https://www.linux.org/'
    },
   
    {
        name: 'ansible',
        logo: ansible,
        url: 'https://www.ansible.com/'
    },
    
    {
        name: 'Salt',
        logo: salt,
        url: ''
    },
    
    {
        name: 'Milesight',
        logo: milesight,
        url: 'https://www.googleadservices.com/pagead/aclk?sa=L&ai=DChcSEwj_2cyu0sGGAxW-pGYCHdDMAJEYABAAGgJzbQ&ase=2&gclid=Cj0KCQjw9vqyBhCKARIsAIIcLMF2YbTc0lQUzW5dnruWKuLTyJj4qLWNQdIu9Wu4ZzRKPXzyE9KuYkMaAmsLEALw_wcB&ohost=www.google.com&cid=CAESVuD2ajIdbBSkJV9aiZE55VbPiH7I5K1YMkdlvaBGUorIxqphXAe5QIEq2OJAGrYI3oXSRFWMdOg4y3NruguOk_uLIr1VbwhKo-V7xDqoVclPwXCfw8yQ&sig=AOD64_1TUaEPJsanQSUY1wQQqsroTLyggw&q&nis=4&adurl&ved=2ahUKEwiY78Wu0sGGAxXA-DgGHUC_CDEQ0Qx6BAgGEAE'
    },
    
    {
        name: 'LoRaWAN',
        logo: lorawan,
        url: 'https://lora-alliance.org/about-lorawan/'
    },
    
    {
        name: 'JIYI',
        logo: jiyi,
        url: 'https://www.jiyiuav.com/'
    },
    
    {
        name: 'DJI',
        logo: dji,
        url: 'https://www.dji.com/'
    },
    
    {
        name: 'Alertmanager',
        logo: alertmanager,
        url: 'https://prometheus.io/docs/alerting/latest/alertmanager/'
    },
    
    {
        name: 'Vutlan',
        logo: vutlan,
        url: 'https://vutlan.com/'
    },
    
    {
        name: 'XAG',
        logo: xag,
        url: 'https://www.xa.com/en'
    },
    
    {
        name: 'Zabbix',
        logo: zabbix,
        url: 'https://www.zabbix.com/index'
    }
  
]





const Technologies = () => {
    return (
        <section  data-scrollax-parent="true" id="technologies">
            <div className="section-subtitle"  data-scrollax="properties: { translateY: '-250px' }" >Technologies<span>//</span></div>
            <div className="container" >
                <div className="section-title fl-wrap">
                    <h3>Technology Stack</h3>
                    <h2>TECHNOLOGY <span>CAPABILITIES</span></h2>
                 
                </div>
            </div>
        
         
            <div className="fl-wrap">
                <div className="container">
                    <ul className="client-list client-list-white">
                        {clients.map(client => (
                            <li key={client.name}><a href={client.url} target="_blank"><img src={client.logo} className='respimg' alt={client.name} title={client.name} /></a></li>
                        ))}
                    </ul>
                </div>
            </div>
            <div className="sec-lines"></div>
        </section>
    )
}

export default Technologies
