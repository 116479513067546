import React from 'react'
import NumbersBgImage from '../images/bg/bg-stats.jpg'
import Layout from "../components/layout"
import { Link } from "gatsby"
import Footer from '../components/footer'



import factories from '../images/services/new/pragicts-digital-outreach-verticals-factories.jpg'
import hospitality from '../images/services/new/pragicts-digital-outreach-verticals-hospitality.jpg'
import buildings from '../images/services/new/pragicts-digital-outreach-verticals-buildings.jpg'

import storage from '../images/services/new/pragicts-digital-outreach-verticals-cold-storage.jpg'

import drones from '../images/services/new/pragicts-digital-outreach-verticals-agriculture-drones.jpg'

import it from '../images/services/new/pragicts-digital-outreach-verticals-it.jpg'
import hospitals from '../images/services/new/pragicts-digital-outreach-verticals-hospitals.png.jpg'
import industrial from '../images/services/new/pragicts-digital-outreach-verticals-industrial.jpg'

import husbandry from '../images/services/new/pragicts-digital-outreach-animal-husbandry-agriculture.jpg'

const HomeStats = () => {

    return (
        <section className="dark-bg no-padding" id="verticals">
             <div className="section-subtitle" data-scrollax="properties: { translateY: '-250px' }" ><span>//</span>Verticals </div>
            <div >
                <div className="section-title fl-wrap">
                    <h3>Our Verticals</h3>
                    <h2>OUTREACH  <span> VERTICALS</span></h2>
          
                </div>
        <div className="fs-carousel-wrap fl-wrap full-height slider-carousel-wrap">
            <div className="fs-carousel-2 fl-wrap full-height cur_carousel-slider-container">
                <div style={{ paddingTop: '75px', paddingBottom: '75px' }} className="fs-carousel-item new">
                    <div className="fs-carousel-title fl-wrap">

                        <h3><Link to="/services">Agriculture / Farming </Link></h3>
                        <Link to="/services" className="fs-carousel-link">More Details</Link>
                    </div>
                    <div className="carousle-item-number"><span>01.</span></div>
                    <div className="bg"  data-bg={drones}></div>
                    <div className="overlay"></div>
                </div>
                <div style={{ paddingTop: '75px', paddingBottom: '75px' }} className="fs-carousel-item new">
                    <div className="fs-carousel-title fl-wrap">

                        <h3><a target="_blank" href="/services">Animal Husbandry (Poultry, etc.) </a></h3>
                        <a target="_blank" href="/services" className="fs-carousel-link">More Details</a>
                    </div>
                    <div className="carousle-item-number"><span>02.</span></div>
                    <div className="bg"  data-bg={husbandry}></div>
                    <div className="overlay"></div>
                </div>
                <div style={{ paddingTop: '75px', paddingBottom: '75px' }} className="fs-carousel-item new">
                    <div className="fs-carousel-title fl-wrap">

                        <h3><a target="_blank" href="/services">Factories</a></h3>
                        <a target="_blank" href="/services" className="fs-carousel-link">More Details</a>
                    </div>
                    <div className="carousle-item-number"><span>03.</span></div>
                    <div className="bg"  data-bg={factories}></div>
                    <div className="overlay"></div>
                </div>
                <div style={{ paddingTop: '75px', paddingBottom: '75px' }} className="fs-carousel-item new">
                    <div className="fs-carousel-title fl-wrap">

                        <h3><a target="_blank" href="/services">Industrial Buildings</a></h3>
                        <a target="_blank" href="/services" className="fs-carousel-link">More Details</a>
                    </div>
                    <div className="carousle-item-number"><span>04.</span></div>
                    <div className="bg"  data-bg={industrial}></div>
                    <div className="overlay"></div>
                </div>
                <div style={{ paddingTop: '75px', paddingBottom: '75px' }} className="fs-carousel-item new">
                    <div className="fs-carousel-title fl-wrap">

                        <h3><a target="_blank" href="/services">Storage (Cold Rooms, etc.)</a></h3>
                        <a target="_blank" href="/services" className="fs-carousel-link">More Details</a>
                    </div>
                    <div className="carousle-item-number"><span>05.</span></div>
                    <div className="bg"  data-bg={storage}></div>
                    <div className="overlay"></div>
                </div>
                <div style={{ paddingTop: '75px', paddingBottom: '75px' }} className="fs-carousel-item new">
                    <div className="fs-carousel-title fl-wrap">

                        <h3><a target="_blank" href="/services">IT</a></h3>
                        <a target="_blank" href="/services" className="fs-carousel-link">More Details</a>
                    </div>
                    <div className="carousle-item-number"><span>06.</span></div>
                    <div className="bg"  data-bg={it}></div>
                    <div className="overlay"></div>
                </div>
                <div style={{ paddingTop: '75px', paddingBottom: '75px' }} className="fs-carousel-item new">
                    <div className="fs-carousel-title fl-wrap">

                        <h3><a target="_blank" href="/services">Buildings (Residential and Commercial)</a></h3>
                        <a target="_blank" href="/services" className="fs-carousel-link">More Details</a>
                    </div>
                    <div className="carousle-item-number"><span>07.</span></div>
                    <div className="bg"  data-bg={buildings}></div>
                    <div className="overlay"></div>
                </div>
                <div style={{ paddingTop: '75px', paddingBottom: '75px' }} className="fs-carousel-item new">
                    <div className="fs-carousel-title fl-wrap">

                        <h3><a target="_blank" href="/services">Healthcare (Hospitals, Clinics, etc)</a></h3>
                        <a target="_blank" href="/services" className="fs-carousel-link">More Details</a>
                    </div>
                    <div className="carousle-item-number"><span>08.</span></div>
                    <div className="bg"  data-bg={hospitals}></div>
                    <div className="overlay"></div>
                </div>
                <div style={{ paddingTop: '75px', paddingBottom: '75px' }} className="fs-carousel-item new">
                    <div className="fs-carousel-title fl-wrap">

                        <h3><a target="_blank" href="/services">Hotels and Resorts</a></h3>
                        <a target="_blank" href="/services" className="fs-carousel-link">More Details</a>
                    </div>
                    <div className="carousle-item-number"><span>09.</span></div>
                    <div className="bg"  data-bg={hospitality}></div>
                    <div className="overlay"></div>
                </div>
               
            </div>
            <div className="sp-cont sarr-contr sp-cont-prev"><i className="fal fa-arrow-left"></i></div>
            <div className="sp-cont sarr-contr sp-cont-next"><i className="fal fa-arrow-right"></i></div>
            <div className="slider-nav-counter-2"></div>
        </div>
        </div>
    </section>

    )
}

export default HomeStats
