import React from 'react'
import NumbersBgImage from '../images/bg/bg-stats.jpg'
import Layout from "../components/layout"
import { Link } from "gatsby"
import Footer from '../components/footer'



import implementation from '../images/services/new/outreach-services-implementation.jpg'
import monitoring from '../images/services/new/outreach-services-centralized-monitoring.jpg'
import consulting from '../images/services/new/outreach-services-consulting.jpg'

const HomeStats = () => {

    return (
        <section className="dark-bg no-padding" id="services">
             <div className="section-subtitle" data-scrollax="properties: { translateY: '-250px' }" ><span>//</span>Services </div>
            <div >
                <div className="section-title fl-wrap">
                    <h3>Our Services</h3>
                    <h2>OUTREACH  <span> Services</span></h2>
          
                </div>
        <div className="fs-carousel-wrap fl-wrap full-height slider-carousel-wrap">
            <div className="fs-carousel fl-wrap full-height cur_carousel-slider-container">
                <div style={{ paddingTop: '75px', paddingBottom: '75px' }} className="fs-carousel-item new">
                    <div className="fs-carousel-title fl-wrap">

                        <h3><Link to="/services">Digital Outreach Consulting </Link></h3>
                        <Link to="/services" className="fs-carousel-link">More Details</Link>
                    </div>
                    <div className="carousle-item-number"><span>01.</span></div>
                    <div className="bg"  data-bg={consulting}></div>
                    <div className="overlay"></div>
                </div>
                <div style={{ paddingTop: '75px', paddingBottom: '75px' }} className="fs-carousel-item new">
                    <div className="fs-carousel-title fl-wrap">

                        <h3><a target="_blank" href="/services">Solution Implementation, Management, and Support </a></h3>
                        <a target="_blank" href="/services" className="fs-carousel-link">More Details</a>
                    </div>
                    <div className="carousle-item-number"><span>02.</span></div>
                    <div className="bg"  data-bg={implementation}></div>
                    <div className="overlay"></div>
                </div>
                <div style={{ paddingTop: '75px', paddingBottom: '75px' }} className="fs-carousel-item new">
                    <div className="fs-carousel-title fl-wrap">

                        <h3><a target="_blank" href="/services">Centralized and Unified Tracking and Action Platform</a></h3>
                        <a target="_blank" href="/services" className="fs-carousel-link">More Details</a>
                    </div>
                    <div className="carousle-item-number"><span>03.</span></div>
                    <div className="bg"  data-bg={monitoring}></div>
                    <div className="overlay"></div>
                </div>
                
    
               
            </div>
            <div className="sp-cont sarr-contr sp-cont-prev"><i className="fal fa-arrow-left"></i></div>
            <div className="sp-cont sarr-contr sp-cont-next"><i className="fal fa-arrow-right"></i></div>
            <div className="slider-nav-counter"></div>
        </div>
        </div>
    </section>

    )
}

export default HomeStats
